import { selectNavigation, useAppSelector } from '@sweb-front/store';

import { ApprovedIconPage, Button } from '@vat/components';
import { Trans, useTranslation } from 'react-i18next';
import {
  ContentWrapper,
  LoanHasBeenAcceptedContainer,
  LoanHasBeenAcceptedParagraph,
  ReceptionParagraph,
  SVGIconWrapper,
  SectionWrapper,
  TitleCmp,
  CustomizedValidateButtonWrapper,
  DocumentInPersonalAccountParagraphExplanation,
} from './styles';
import useApprovedPayment from './useApprovedPayment';
import { CustomedBubbleInfo } from '@sweb-front/components';
import { CHANELTYPE } from '@sweb-front/types';

const ApprovedPayment = () => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const { distributionChannelCD } = opportunity;
  const parameters = useAppSelector((state) => state.parameters.state);
  const navigation = useAppSelector(selectNavigation);

  const { onContractClick } = useApprovedPayment(
    opportunity,
    parameters,
    navigation
  );

  const { wayType } = parameters;
  const wayTypeDisplay =
    wayType === 'VATC' ? (
      <DocumentInPersonalAccountParagraphExplanation>
        {t(`responses.accepted.${wayType}.getContractCopy`)}
      </DocumentInPersonalAccountParagraphExplanation>
    ) : wayType === 'VATR' ? (
      <>
        <DocumentInPersonalAccountParagraphExplanation>
          {t(`responses.accepted.${wayType}.firstGetContractCopy`)}
        </DocumentInPersonalAccountParagraphExplanation>
        <DocumentInPersonalAccountParagraphExplanation>
          {t(`responses.accepted.${wayType}.secondGetContractCopy`)}
        </DocumentInPersonalAccountParagraphExplanation>
      </>
    ) : (
      <></>
    );

  return (
    <SectionWrapper>
      <SVGIconWrapper>
        <ApprovedIconPage />
      </SVGIconWrapper>
      <ContentWrapper>
        <TitleCmp>{t('common.congratulations')}</TitleCmp>
        <LoanHasBeenAcceptedContainer>
          <LoanHasBeenAcceptedParagraph>
            {t('responses.accepted.definitely')}
          </LoanHasBeenAcceptedParagraph>
        </LoanHasBeenAcceptedContainer>
        <ReceptionParagraph>
          {t('responses.accepted.receiptDocuments')}
        </ReceptionParagraph>
        <CustomedBubbleInfo
          id="approvedPaymentPage"
          descriptionComp={
            <Trans
              i18nKey="responses.accepted.downloadDocuments"
              components={{
                contractspace: (
                  <span>{t('responses.accepted.downloadDocumentsBold')}</span>
                ),
              }}
            />
          }
        ></CustomedBubbleInfo>
        <CustomizedValidateButtonWrapper
          style={
            distributionChannelCD === CHANELTYPE.POS
              ? {
                  marginTop: '3.2rem',
                }
              : {}
          }
        >
          <Button
            id="contract-download-button-approved-payment"
            level="primary"
            onClick={onContractClick}
            isBold
          >
            {t('responses.buttons.contractArea')}
          </Button>
        </CustomizedValidateButtonWrapper>
        {wayTypeDisplay}
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default ApprovedPayment;
