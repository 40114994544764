import { Trans, useTranslation } from 'react-i18next';
import {
  FooterParagraph,
  ImgWrapper,
  LogoWrapper,
  MainWrapper,
  ParagraphWrapper,
  SeparatorWrapper,
  FirstParagraphWrapper,
  CenterParagraphWrapper,
  ConnectParagraphWrapper,
  ParagraphTelWrapper,
} from './styles';

const SmsMentionLegale = () => {
  const { t } = useTranslation();
  return (
    <MainWrapper>
      <LogoWrapper>
        <img alt="logo_cetelem" src="/logos/cetelm.svg" />
      </LogoWrapper>
      <FirstParagraphWrapper>
        <p>{t('smsmentionlegale.options.toList')}</p>
        <ul>
          <li>
            <Trans
              i18nKey="smsmentionlegale.options.personalSpace"
              components={{
                Space: <strong>cmp1</strong>,
                Site: (
                  <a
                    href="https://www.cetelem.fr/fr/accueil"
                    target="_blank"
                    rel="noreferrer"
                  >
                    cmp2
                  </a>
                ),
              }}
            />
          </li>
          <li>
            <Trans
              i18nKey="smsmentionlegale.options.mobileApplication"
              components={{
                appStore: <strong>appStore</strong>,
                googlePlay: <strong>googlePlay</strong>,
              }}
            />
          </li>
        </ul>
      </FirstParagraphWrapper>
      <ImgWrapper>
        <a
          href="https://tr.np6.com/r5.aspx?GV1=DJAY02D0001330000AGF00000000&mperfguidactv5=000000"
          target="_blank"
          rel="noreferrer"
        >
          <img alt="Lien App Store " src="/images/app_store.svg" />
        </a>
        <a
          href="https://tr.np6.com/r5.aspx?GV1=DJAY02D0001330000AGG00000000&mperfguidactv5=000000"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="google-play"
            alt="Lien Google Play"
            src="/images/google_play.svg"
          />
        </a>
      </ImgWrapper>
      <ParagraphWrapper>
        <p>
          <Trans
            i18nKey="smsmentionlegale.toConnect"
            components={{
              IdClient: <strong>IdClient</strong>,
              Code: <strong>Code</strong>,
            }}
          />
        </p>
      </ParagraphWrapper>
      <CenterParagraphWrapper>
        <ul>
          <li>
            <strong>{t('smsmentionlegale.forgotIdentifier')}</strong>
          </li>
        </ul>
      </CenterParagraphWrapper>
      <ParagraphWrapper>
        <p>
          <Trans
            i18nKey="smsmentionlegale.forgotIdentifierHelper"
            components={{
              personalSpace: <strong>personalSpace</strong>,
            }}
          />
        </p>
      </ParagraphWrapper>
      <ParagraphWrapper>
        <p>{t('smsmentionlegale.FirstConnexionuseSecretCode')}</p>
      </ParagraphWrapper>
      <CenterParagraphWrapper>
        <ul>
          <li>
            <strong>{t('smsmentionlegale.forgotPassword')}</strong>
          </li>
        </ul>
      </CenterParagraphWrapper>
      <ParagraphWrapper>
        <p>{t('smsmentionlegale.forgotPasswordHelper')}</p>
      </ParagraphWrapper>
      <ParagraphTelWrapper>
        <p>{t('smsmentionlegale.otherQuestion')}</p>
        <a
          href="https://tr.np6.com/r5.aspx?GV1=DJAY02D0001330000AGH00000000&mperfguidactv5=000000"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/telephone.svg" alt="09 69 36 55 63" />
        </a>
      </ParagraphTelWrapper>
      <ConnectParagraphWrapper>
        <p>{t('smsmentionlegale.connectToseeOtherFunctionalities')}</p>
      </ConnectParagraphWrapper>
      <SeparatorWrapper />
      <FooterParagraph>
        <Trans
          i18nKey="smsmentionlegale.footer"
          components={{
            oriasSite: (
              <a href="https://tr.np6.com/r5.aspx?GV1=DJAY02D0001330000AGI00000000&mperfguidactv5=000000">
                oriasSite
              </a>
            ),
          }}
        />
      </FooterParagraph>
    </MainWrapper>
  );
};

export default SmsMentionLegale;
