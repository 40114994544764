import { useTranslation } from 'react-i18next';
import { Section } from '@sweb-front/components';
import useObConnexionError from './useObConnexionError';
import { useAppSelector } from '@sweb-front/store';
import {
  ObButtonAWrapper,
  ObContentWrapper,
  ObTitleWrapper,
  StyledContinueButtonWrapper,
} from './style';
import { StyledButtonLoader } from '@sweb-front/styles';
import { findPriOverdraftAmount, trackEvent } from '@sweb-front/utils';
import { useEffect } from 'react';
import { usePageRedirection } from '@sweb-front/hooks';
import { OBCONNEXIONERROR } from '@vat/configuration';
import { setIdHashed } from 'src/containers/forms/utils';
import { CHANELTYPE } from '@sweb-front/types';

const ObConnexionError = () => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const parameters = useAppSelector((state) => state.parameters.state);
  const { onSubmit, isActionLoading } = useObConnexionError(opportunity);
  const { isPageLoading } = usePageRedirection(OBCONNEXIONERROR, {
    locked: true,
  });

  useEffect(() => {
    if (opportunity?.opportunityIdExt) {
      const { financialDetails } = opportunity?.offers?.[0]?.loans?.[0] ?? {};
      const isMono: boolean = opportunity?.bagType === 'MONO';
      trackEvent({
        event: 'popinDisplay',
        Telesales:
          (opportunity?.distributionChannelCD === CHANELTYPE.TEL)?.toString() ??
          'false',
        site: 'Ecommerce',
        workflow: parameters?.wayCd,
        pageName: 'E-Commerce : Open Banking : Connexion perdue',
        environment: (window as unknown as WindowWithEnv)._env_?.env,
        visitorStatus: 'non-logged',
        Amount: (isMono
          ? financialDetails?.overdraftAmt
          : findPriOverdraftAmount(opportunity.offers[0].loans)
        )?.toString(),
        Rate: isMono ? financialDetails?.tncRt?.toString() : undefined,
        Term: isMono ? financialDetails?.term?.toString() : undefined,
        MonthlyPayment: isMono
          ? financialDetails?.monthlyPaymentWithoutInsuranceAmt?.toString()
          : undefined,
        opportunityIdHashed: setIdHashed(opportunity),
        ContributorCode: opportunity.distributor.distributorNb,
        pathType: isMono ? 'mono panier' : 'multi panier',
      });
    }
  }, []);

  return (
    !isPageLoading && (
      <>
        <Section title="" description="">
          <ObTitleWrapper>
            {t('openBanking.obConnexionError.title')}
          </ObTitleWrapper>

          <ObContentWrapper>
            {t('openBanking.obConnexionError.description')}
          </ObContentWrapper>
          <ObButtonAWrapper>
            <StyledContinueButtonWrapper onClick={onSubmit}>
              {isActionLoading ? (
                <StyledButtonLoader
                  isLoading
                  isTextInline
                  isHideBackground={false}
                />
              ) : (
                t('openBanking.obConnexionError.button')
              )}
            </StyledContinueButtonWrapper>
          </ObButtonAWrapper>
        </Section>
      </>
    )
  );
};

export default ObConnexionError;
