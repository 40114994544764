import styled from 'styled-components';

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  padding: 1.4rem 0 2rem 0;
`;

export const ModalRadioGroupContainer = styled.div`
  padding: 0 2.5%;
  max-height: 90vh;
  height: calc(100% - 6.4rem);
`;

export const ActivitySectorContainer = styled.div`
  margin: 0;
`;

export const InputDateWrapper = styled.div`
  margin-bottom: 16px;
`;
