import { createContext, useEffect, useState } from 'react';
import type { EventParams } from '@sweb-front/types';
import { useAdobeTagManager } from '@sweb-front/hooks';
import { useAppSelector, selectConfiguration } from '@sweb-front/store';

export interface AdobeTagManagerProps {
  children: React.ReactNode;
  LOGGER?: Record<string, (...args: unknown[]) => void> | Console;
}

export type IContext = (event: EventParams) => void;

export const Context = createContext<IContext>(
  // eslint-disable-next-line no-void
  () => void 0
);

const AdobeTagManager = ({
  children,
  LOGGER = console,
}: AdobeTagManagerProps): React.ReactElement => {
  const { trackEvent } = useAdobeTagManager({ LOGGER });
  const configuration = useAppSelector(selectConfiguration);
  const [hasLoadedTMScript, setHasLoadedTMScript] = useState(false);

  useEffect(() => {
    try {
      // Init the tag manager and dataLayer
      window.dataLayer = [
        { 'gtm.start': new Date().getTime(), event: 'gtm.js' },
      ];
      // Init the google tag manager script
      const googletagmanagerScript = document.createElement('script');
      googletagmanagerScript.async = true;
      googletagmanagerScript.src = `//www.googletagmanager.com/gtm.js?id=GTM-K5WNH84`;
      document.head.appendChild(googletagmanagerScript);

      return () => {
        document.head.removeChild(googletagmanagerScript);
      };
    } catch (error) {
      LOGGER.error(error);
    }
    return () => {};
  }, []);

  // Updating Tag Manager data from config
  useEffect(() => {
    // Init the adobe tag manager script on url change
    try {
      if (configuration?.launchSnippetUrl && !hasLoadedTMScript) {
        const adobedtmScript = document.createElement('script');
        adobedtmScript.async = true;
        adobedtmScript.src = configuration?.launchSnippetUrl || '';
        document.head.appendChild(adobedtmScript);

        // Used to prevent apending multiple scripts on launchSnippetUrl change
        setHasLoadedTMScript(true);
      }
    } catch (error) {
      LOGGER.error(error);
    }
  }, [configuration?.launchSnippetUrl]);

  return <Context.Provider value={trackEvent}>{children}</Context.Provider>;
};

export default AdobeTagManager;
