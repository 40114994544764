import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@sweb-front/store';
import { BubbleInfo, RefusedIconPage } from '@vat/components';
import useRefusedPayment from './useRefusedPayment';
import {
  ContentWrapper,
  SectionWrapper,
  SVGIconWrapper,
  TitleCmp,
  Paragraph,
  ContentParagraph,
  BoldParagraph,
} from './styles';
import { IncomeDetailsInfoWrapper } from 'src/containers/forms/IncomeExpensesForm/styles';
import { useRef } from 'react';

const RefusedPayment = () => {
  const { t } = useTranslation();
  const parameters = useAppSelector((state) => state.parameters.state);
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const { person } = opportunity;
  const { wayType } = useRefusedPayment(opportunity, parameters);

  const incomeDetailsRefRefusedPage = useRef(null);

  return (
    <SectionWrapper>
      <SVGIconWrapper>
        <RefusedIconPage />
      </SVGIconWrapper>
      <ContentWrapper>
        <TitleCmp>{t('responses.refused.title')}</TitleCmp>
        <ContentParagraph>{t('responses.refused.gratitude')}</ContentParagraph>
        {wayType === 'VATC' && (
          <BoldParagraph>
            {t('responses.refused.securedBankInformations')}
          </BoldParagraph>
        )}
        <ContentParagraph>
          {t('responses.refused.otherPaymentWay')}
        </ContentParagraph>
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default RefusedPayment;
