import { useRipple } from '@vat/utils';
import { useState } from 'react';
import {
  RadioGroupContainer,
  RadioGroupErrorMessage,
  RadioGroupItem,
  RadioGroupLabel,
  RadioGroupTitle,
  RadioGroupWrapper,
} from './styles';

export type RadioGroupProps = {
  id: string;
  value?: string;
  label: string;
  options: { label: string; value: string }[];
  onChangeEvent?: (e) => void;
  onChange?: (newValue: { id: string; value: string }) => void;
  errorMessage?: string;
  buttonWidth?: string;
  buttonHeight?: string;
  columnGap?: string;
  rowGap?: string;
  labelPosition?: string;
  wrapOnMobile?: boolean;
  touched?: boolean;
  title?: string;
};

export const RadioGroup = ({
  id,
  value: valueProp,
  label,
  options,
  onChange,
  onChangeEvent,
  labelPosition = 'left' || 'right' || 'center',
  errorMessage,
  buttonWidth = '14rem',
  buttonHeight = '5.6rem',
  columnGap = '0.5rem',
  rowGap = '0.5rem',
  wrapOnMobile = true,
  touched = false,
  title,
}: RadioGroupProps) => {
  const [value, setValue] = useState<string | undefined>(valueProp);
  const { createRipple } = useRipple();

  const onClick = (event: any, v: string) => {
    setValue(v);
    if (onChangeEvent) {
      onChangeEvent({ target: { id, value: v } });
    }
    if (onChange) {
      onChange({ id, value: v });
    }
    createRipple(event);
  };
  const isError = touched && !value && errorMessage !== undefined;
  return (
    <RadioGroupWrapper id={id} data-testid={id} className={id}>
      {title && <RadioGroupTitle>{title}</RadioGroupTitle>}
      <RadioGroupLabel>{label}</RadioGroupLabel>
      <RadioGroupContainer
        id={id}
        buttonWidth={buttonWidth}
        buttonHeight={buttonHeight}
        columnGap={columnGap}
        rowGap={rowGap}
        wrapOnMobile={wrapOnMobile}
      >
        {options.map((option, i) => (
          <RadioGroupItem
            key={`radio-item-${i}`}
            id={id}
            onClick={(e) => onClick(e, option.value)}
            type="button"
            className={value === option.value ? 'selected' : ''}
            isError={isError}
          >
            {option.label}
          </RadioGroupItem>
        ))}
      </RadioGroupContainer>
      {isError ? (
        <RadioGroupErrorMessage id="errorMessage">
          {errorMessage}
        </RadioGroupErrorMessage>
      ) : (
        <></>
      )}
    </RadioGroupWrapper>
  );
};

export default RadioGroup;
