import { ICommitmentLevel } from '@sweb-front/types';
import { SCORE } from '@vat/configuration';
import { getAcceptationPolicyPromise } from '@vat/services';

import { AxiosResponse } from 'axios';
import { useCallback } from 'react';

const useAcceptationPolicy = () => {
  const getAcceptationPolicy = useCallback(
    async (
      paymentMode: 'RIB' | 'CB',
      step:
        | 'KYC'
        | 'SOLVA'
        | 'ASSU'
        | 'CB'
        | 'ONBO'
        | 'BANK_3DS'
        | 'DECISION_RVO',
      callbackSuccess: (result: AxiosResponse<ICommitmentLevel>) => void,
      callbackError?: (e) => void
    ) => {
      try {
        const response = await getAcceptationPolicyPromise(paymentMode, step);
        callbackSuccess?.(response);
        if (step == 'ONBO' || step == 'BANK_3DS') {
          localStorage.setItem(SCORE, 'final');
        }
        return response;
      } catch (errCode) {
       return callbackError?.(errCode as number);
      }
    },
    []
  );
  return { getAcceptationPolicy };
};
export default useAcceptationPolicy;
