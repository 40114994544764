import {
  useAcceptationPolicy,
  useOpenBankingRedirect,
} from '@sweb-front/hooks';
import { axiosInstance } from '@sweb-front/services';
import {
  IAppStep,
  selectNavigation,
  setCardPayment,
  setEndParcours,
  setLastAppUrl,
  setRandomSign,
  updateOpportunityStatus,
  updateParcoursNavigation,
  updateSteps,
  useAppDispatch,
  useAppSelector,
} from '@sweb-front/store';
import { ICardPayment, IUrlData } from '@sweb-front/types';
import { initRandomValue, isStringEmpty } from '@sweb-front/utils';
import {
  DOAUTHORIZATION,
  ERRORPAGE,
  INFORMATIONBANCAIRES,
  LIVENESS,
  OPPORTUNITYSTATUS,
  PIECESJUSTIFICATIVES,
  REFUSEDREPONSE,
  SUCCESSREPONSE,
  THREEDS,
  WAITINGRESPONSE,
} from '@vat/configuration';
import {
  createECard,
  doAuthorization,
  getDsCardUrl,
  getOnboardingUrl,
} from '@vat/services';
import { IForm, createAndSubmitForm, PageLoadingContext } from '@vat/utils';
import { AxiosResponse } from 'axios';
import { useCallback, useContext } from 'react';
import { useCloseOpportunity } from '../useCloseOpportunity';
import { useNavigate } from 'react-router-dom';

const useManagingExternalApp = () => {
  const { updateAbortErrorMessage } = useCloseOpportunity();
  const { getAcceptationPolicy } = useAcceptationPolicy();
  const parameters = useAppSelector((state) => state.parameters.state);
  const isVATRib = parameters?.wayCd?.toLowerCase().includes('rib');
  const dispatch = useAppDispatch();
  const goToExternalAppByForm = useCallback((activeStep: IAppStep) => {
    createAndSubmitForm(
      activeStep.externalUrl ?? '',
      activeStep.dataRetrievedToExternalUrl?.method ?? 'POST',
      activeStep.dataRetrievedToExternalUrl?.formDetails as IForm,
      activeStep.dataRetrievedToExternalUrl?.urlData ?? ([] as IUrlData[])
    );
  }, []);
  const appNavigation = useAppSelector(selectNavigation);
  const updateIsAppLoading = useContext(PageLoadingContext);
  const navigate = useNavigate();
  const opportunity = useAppSelector((state) => state.opportunity.state);

  const { waitingPageBeforeOpenBanking } = useOpenBankingRedirect(opportunity);

  const initMonext = useCallback(async (callback?: () => void) => {
    const returnUrl = encodeURIComponent(
      `${window.location.origin}?redirectFromMonext`
    );
    dispatch(
      updateSteps({
        externalAppName: 'monext',
        returnUrl,
      })
    );
    const returnUrlParam = `returnUrl=${returnUrl}`;
    try {
      const response: AxiosResponse<ICardPayment> = await axiosInstance().get(
        `vendors/opportunities/v1/payment?${returnUrlParam}`
      );
      const form = response.data;
      dispatch(setCardPayment(response.data));
      createAndSubmitForm(
        form.url,
        form.method,
        {
          id: 'monext',
          name: 'monext',
          target: '_parent',
        },
        form.urlData,
        () => {
          callback?.();
          dispatch(
            updateSteps({
              externalAppName: 'monext',
              isSeDone: false,
              externalUrl: form.url,
              dataRetrievedToExternalUrl: {
                formDetails: {
                  id: 'monext',
                  name: 'monext',
                  target: '_parent',
                },
                method: form.method,
                action: form.url,
                urlData: form.urlData,
              },
              isExternalUrlAlreadyVisited: true,
              params: undefined,
            })
          );
          dispatch(
            updateParcoursNavigation({
              name: INFORMATIONBANCAIRES,
              loaded: true,
              actionPageDone: false,
              disabled: true, // to tell that if we go back these step will be ignored and his -1 will be loaded
              params: {
                method: 'POST',
                url: form.url,
                dataRetrievedToExternalUrl: {
                  formDetails: {
                    id: 'monext',
                    name: 'monext',
                    target: '_parent',
                  },
                  method: form.method,
                  action: form.url,
                  urlData: form.urlData,
                },
              },
            })
          );
        }
      );
    } catch (e) {
      dispatch(
        updateSteps({
          externalAppName: 'monext',
          isErrorHappened: true,
        })
      );
      dispatch(
        updateParcoursNavigation({
          name: INFORMATIONBANCAIRES,
          loaded: true,
          actionPageDone: false,
          disabled: false,
          params: {
            hasError: true,
          },
        })
      );
      updateAbortErrorMessage('Le débranchement vers Monext est KO');
      navigate(ERRORPAGE, {
        replace: true,
      });
    }
  }, []);

  const initOnBo = useCallback((callback?: () => void) => {
    const randomValue = initRandomValue();
    const returnUrlOnboarding = encodeURIComponent(
      `${window.location.origin}?redirectFromSignature&signed=${randomValue}`
    );
    dispatch(
      updateSteps({
        externalAppName: 'onbo',
        isSeDone: false,
        isExternalUrlAlreadyVisited: false,
      })
    );
    dispatch(
      updateParcoursNavigation({
        name: PIECESJUSTIFICATIVES,
        loaded: true,
        locked: true,
        actionPageDone: false,
      })
    );
    dispatch(setRandomSign(`${randomValue}`));
    getOnboardingUrl(
      returnUrlOnboarding,
      (onboardingResponse) => {
        if (onboardingResponse && onboardingResponse.url) {
          callback?.();
          dispatch(
            updateSteps({
              externalAppName: 'onbo',
              externalUrl: onboardingResponse.url,
              isExternalUrlAlreadyVisited: true,
            })
          );
          dispatch(
            updateParcoursNavigation({
              name: PIECESJUSTIFICATIVES,
              params: {
                url: onboardingResponse.url,
              },
            })
          );
          window.location.replace(onboardingResponse.url);
        }
      },
      () => {
        dispatch(
          updateParcoursNavigation({
            name: PIECESJUSTIFICATIVES,
            actionPageDone: false,
            params: {
              hasError: true,
            },
          })
        );
        dispatch(
          updateSteps({
            externalAppName: 'onbo',
            externalUrl: '',
            isExternalUrlAlreadyVisited: false,
            isErrorHappened: true,
          })
        );
        updateAbortErrorMessage('Le débranchement vers OnBo est KO');
        navigate(ERRORPAGE, {
          replace: true,
        });
      }
    );
  }, []);

  const waitingPageBeforeOnbo = async () => {
    await getAcceptationPolicy(
      isVATRib ? 'RIB' : 'CB',
      'SOLVA',
      (response) => {
        dispatch(
          updateSteps({
            externalAppName: isVATRib ? 'parcours' : 'monext',
            isSeDone: true,
            isErrorHappened: false,
          })
        );
        if (response.data.scoreLightCd === 'R') {
          dispatch(setEndParcours(true));
          dispatch(setLastAppUrl(REFUSEDREPONSE));
          dispatch(updateOpportunityStatus(OPPORTUNITYSTATUS.REFU));
          dispatch(
            updateParcoursNavigation({
              name: PIECESJUSTIFICATIVES,
              actionPageDone: true,
              locked: true,
              disabled: false,
            })
          );
          navigate(REFUSEDREPONSE);
        } else if (response.data.liveness === true) {
          dispatch(setLastAppUrl(LIVENESS));
          window.location.replace(LIVENESS);
        } else {
          dispatch(
            updateParcoursNavigation({
              name: PIECESJUSTIFICATIVES,
              actionPageDone: false,
              loaded: true,
            })
          );
          initOnBo();
        }
      },
      () => {
        dispatch(
          updateParcoursNavigation({
            name: PIECESJUSTIFICATIVES,
            loaded: true,
            actionPageDone: false,
            disabled: false,
            locked: true,
            params: {
              hasError: true,
            },
          })
        );
        dispatch(
          updateSteps({
            externalAppName: 'onbo',
            isErrorHappened: true,
            waitingPagePath: window.location.pathname + window.location.search,
          })
        );
        updateAbortErrorMessage(
          'score::getAcceptationPolicy an error occured while getting the score before going to onbo or liveness if it is on'
        );
        navigate(ERRORPAGE, {
          replace: true,
        });
      }
    );
  };

  const init3DS = useCallback((params: unknown, callback?: () => void) => {
    const returnUrl = encodeURIComponent(
      `${window.location.origin}?redirectFrom3DS`
    );

    dispatch(
      updateParcoursNavigation({
        name: THREEDS,
        loaded: true,
        params: {
          virtual: true,
        },
      })
    );

    dispatch(
      updateSteps({
        externalAppName: '3ds',
        params: params as Record<string, unknown>,
        isSeDone: false,
        returnUrl,
        isExternalUrlAlreadyVisited: false,
      })
    );

    getDsCardUrl(
      returnUrl,
      (dsCardUrlResponse) => {
        callback?.();
        createAndSubmitForm(
          dsCardUrlResponse?.url,
          dsCardUrlResponse?.method,
          { id: 'Threeds', name: 'Threeds', target: '_parent' },
          dsCardUrlResponse?.urlData ?? ([] as IUrlData[]),
          () => {
            dispatch(
              updateSteps({
                externalAppName: '3ds',
                externalUrl: dsCardUrlResponse?.url ?? '',
                isExternalUrlAlreadyVisited: true,
                dataRetrievedToExternalUrl: {
                  formDetails: {
                    id: 'Threeds',
                    name: 'Threeds',
                    target: '_parent',
                  },
                  method: dsCardUrlResponse?.method ?? 'POST',
                  action: dsCardUrlResponse?.url,
                  urlData: dsCardUrlResponse?.urlData,
                },
              })
            );
            dispatch(
              updateParcoursNavigation({
                name: THREEDS,
                params: {
                  virtual: true,
                  method: 'POST',
                  url: dsCardUrlResponse?.url ?? '',
                  dataRetrievedToExternalUrl: {
                    formDetails: {
                      id: 'Threeds',
                      name: 'Threeds',
                      target: '_parent',
                    },
                    method: dsCardUrlResponse?.method ?? 'POST',
                    action: dsCardUrlResponse?.url,
                    urlData: dsCardUrlResponse?.urlData,
                  },
                },
              })
            );
          }
        );
      },
      () => {
        dispatch(
          updateParcoursNavigation({
            name: THREEDS,
            actionPageDone: false,
            params: {
              virtual: true,
              hasError: true,
            },
          })
        );
        updateAbortErrorMessage(
          "Le débranchement vers l'application 3DS est KO"
        );
        navigate(ERRORPAGE, {
          replace: true,
        });
      }
    );
  }, []);

  const finalizeGlobalLoanRequest = useCallback(
    (params) => {
      finalizeLoanRequesting(params);
    },
    [appNavigation?.steps]
  );

  const executeCreateECard = useCallback(
    async (token: string, params) => {
      dispatch(
        updateSteps({
          params: { ...params, request_2: false }, // pour la create ecard
          externalAppName: 'authorization',
        })
      );

      const response = await createECard(token);
      return response;
    },
    [appNavigation?.steps]
  );

  const executeFinalInstantDecision = useCallback(
    async (params) => {
      dispatch(
        updateSteps({
          params: { ...params, request_3: false }, // pour l'acceptation policy
          externalAppName: 'authorization',
        })
      );
      return await getAcceptationPolicy(
        isVATRib ? 'RIB' : 'CB',
        'BANK_3DS',
        (response) => {
          dispatch(
            updateSteps({
              params: { ...params, request_3: true }, // pour l'acceptation policy
              externalAppName: 'authorization',
            })
          );
          const seDecision = response?.data;
          dispatch(
            updateSteps({
              externalAppName: 'authorization',
              isSeDone: true,
            })
          );
          dispatch(setEndParcours(true));
          dispatch(
            updateParcoursNavigation({
              name: DOAUTHORIZATION,
              actionPageDone: true,
              params,
            })
          );

          if (seDecision?.scoreLightCd === 'V') {
            dispatch(setLastAppUrl(SUCCESSREPONSE));
            dispatch(updateOpportunityStatus(OPPORTUNITYSTATUS.APPR));
            navigate(SUCCESSREPONSE, {
              replace: true,
            });
          } else if (seDecision?.scoreLightCd === 'R') {
            dispatch(setLastAppUrl(REFUSEDREPONSE));
            dispatch(updateOpportunityStatus(OPPORTUNITYSTATUS.REFU));
            navigate(REFUSEDREPONSE, {
              replace: true,
            });
          } else {
            dispatch(setLastAppUrl(WAITINGRESPONSE));
            dispatch(updateOpportunityStatus(OPPORTUNITYSTATUS.PAPP));
            navigate(WAITINGRESPONSE, {
              replace: true,
            });
          }
          updateIsAppLoading(false);
        },
        () => {
          dispatch(
            updateParcoursNavigation({
              name: DOAUTHORIZATION,
              actionPageDone: false,
              params: {
                virtual: true,
                hasError: true,
              },
            })
          );
          dispatch(
            updateSteps({
              externalAppName: 'authorization',
              isErrorHappened: true,
              waitingPagePath:
                window.location.pathname + window.location.search,
            })
          );
          updateAbortErrorMessage(`Service OCR (AcceptationPolicy) KO`);
          navigate(ERRORPAGE, {
            replace: true,
          });
        }
      );
    },
    [appNavigation]
  );

  const finalizeLoanRequesting = useCallback(
    async (params) => {
      dispatch(
        updateParcoursNavigation({
          name: DOAUTHORIZATION,
          actionPageDone: false,
          loaded: true,
          locked: true,
          params: {
            virtual: true,
          },
        })
      );
      dispatch(
        updateSteps({
          params: { ...params, request_1: false },
          externalAppName: 'authorization',
          isSeDone: false,
        })
      );
      try {
        await doAuthorization(
          params.threeDsRef,
          params.returnCode,
          params.returnValue
        );

        dispatch(
          updateSteps({
            externalAppName: 'authorization',
            params: { ...params, request_1: true },
          })
        );

        const token = localStorage.getItem('token') ?? '';
        if (!isStringEmpty(token) && !!appNavigation.rightToEcard) {
          try {
            // Création d'ecard si rightoEcard à true
            await executeCreateECard(token, params);
            dispatch(
              updateSteps({
                params: { ...params, request_1: true, request_2: true },
                externalAppName: 'authorization',
              })
            );
            // Get Final Instant decision
            await executeFinalInstantDecision({
              ...params,
              request_1: true,
              request_2: true,
            });
          } catch {
            updateAbortErrorMessage(`Service de création e-card KO`);
            dispatch(
              updateSteps({
                externalAppName: 'authorization',
                params,
                isErrorHappened: true,
                waitingPagePath:
                  window.location.pathname + window.location.search,
              })
            );
            dispatch(
              updateParcoursNavigation({
                name: DOAUTHORIZATION,
                actionPageDone: false,
                params: {
                  ...params,
                  virtual: true,
                  hasError: true,
                },
              })
            );
            navigate(ERRORPAGE, {
              replace: true,
            });
          }
        } else {
          // Get Final Instant decision
          executeFinalInstantDecision({ ...params, request_1: true });
        }
      } catch {
        dispatch(
          updateParcoursNavigation({
            name: THREEDS,
            params: {
              hasError: true,
            },
          })
        );
        dispatch(
          updateParcoursNavigation({
            name: DOAUTHORIZATION,
            actionPageDone: false,
            params: {
              virtual: true,
              hasError: true,
            },
          })
        );
        dispatch(
          updateSteps({
            externalAppName: 'authorization',
            isErrorHappened: true,
            waitingPagePath: window.location.pathname + window.location.search,
          })
        );
        updateAbortErrorMessage(
          `DoAuthorization n'a pas pu aboutir, paramètres recues en entrée ${Object.entries(
            params ?? {}
          )
            .map(([value, key]) => `${value}: ${key}`)
            .join('; ')}`
        );
        navigate(ERRORPAGE, {
          replace: true,
        });
      }
    },
    [appNavigation]
  );

  return {
    finalizeLoanRequesting,
    goToExternalAppByForm,
    initMonext,
    initOnBo,
    init3DS,
    waitingPageBeforeOnbo,
    waitingPageBeforeOpenBanking,
    finalizeGlobalLoanRequest,
    executeCreateECard,
    executeFinalInstantDecision,
  };
};

export default useManagingExternalApp;
