import { DESKTOP, TAB } from '@sweb-front/utils';
import styled from 'styled-components';

export const RadioGroupWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  max-height: 100vh;
  @media screen and ${TAB} {
    max-height: 80vh;
  }
  &.activitySector-radio-group {
    height: 100%;
  }
`;

export const RadioGroupItem = styled.button<{ isError?: boolean; id?: string }>`
  position: relative;
  overflow: hidden;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
  background: none;
  cursor: pointer;
  border: 1px solid;
  border-color: ${({ theme, isError }) =>
    isError ? theme.vendor.colors.error : theme.vendor.colors.border};
  color: ${({ theme }) => theme.vendor.colors.border};
  border-radius: 4px;
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  text-overflow: clip;
  &.selected {
    border-color: ${({ theme }) => theme.vendor.colors.primaryContrast};
    color: ${({ theme }) => theme.vendor.colors.primaryContrast};
    background-color: ${({ theme }) => theme.vendor.colors.light};
  }
  ${({ id }) => {
    if (id === 'monthNumberBaseCalculation') {
      return `
        min-width: 3.2rem;
        height: 100% !important;
        flex-grow: 1;
        margin: 0 0.4rem 0 0.8rem;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      `;
    } else {
      return ``;
    }
  }}
`;

export const RadioGroupContainer = styled.div<{
  buttonWidth: string;
  buttonHeight: string;
  columnGap: string;
  rowGap: string;
  wrapOnMobile: boolean;
  id?: string;
}>`
  ${({ id, buttonHeight, buttonWidth, rowGap, wrapOnMobile, columnGap }) => {
    if (id === 'monthNumberBaseCalculation') {
      return `
        display: flex;
        height: ${buttonHeight};
      `;
    } else {
      return `
        display: grid;
        overflow-y: auto;
        grid-template-columns: repeat(
          auto-fill,
          ${wrapOnMobile ? '49.25%' : buttonWidth}
        );
        justify-content: space-between;
        grid-gap: ${rowGap} ${wrapOnMobile ? columnGap : '0.5rem'};
        & ${RadioGroupItem} {
          height: ${buttonHeight};
        }
        @media screen and ${DESKTOP} {
          max-height: 55vh;
          :not(#familySituation) {
            overflow: hidden;
            grid-template-columns: repeat(
              auto-fill,
              ${buttonWidth}
            );
            grid-gap: ${rowGap} ${wrapOnMobile ? columnGap : '0.5rem'};
          }
        }
      `;
    }
  }}
`;

export const RadioGroupHiddenInput = styled.input``;

export const RadioGroupErrorMessage = styled.span`
  color: ${({ theme }) => theme.vendor.colors.error};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

export const RadioGroupLabel = styled.span`
  color: ${({ theme }) => theme.vendor.colors.text};
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
`;

export const RadioGroupTitle = styled.h1`
  color: ${({ theme }) => theme.vendor.colors.title};
  font-family: 'Ubuntu Bold';
  margin: 0;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h1};
  line-height: 3.6rem;
  @media screen and ${TAB} {
    font-size: 3.2rem;
    line-height: 4rem;
  }
`;
