import { CustomedBubbleInfo, Section } from '@sweb-front/components';
import { selectDisplays, useAppSelector } from '@sweb-front/store';
import { ascSort } from '@sweb-front/utils';
import { FORM_FIELDS_MAPPING, SOLVA } from '@vat/configuration';
import { IncomeExpensesForm } from '@vat/containers';
import { useAppOptions } from '@vat/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageRedirection } from '@sweb-front/hooks';

const IncomeExpenses = () => {
  const { t } = useTranslation();
  const displays = useAppSelector(selectDisplays) as any;
  const { setAppOptions } = useAppOptions();
  const { isPageLoading } = usePageRedirection(SOLVA);

  const solvaFields = Object.keys(FORM_FIELDS_MAPPING.SOLVA).sort((k, l) =>
    ascSort(FORM_FIELDS_MAPPING.SOLVA[k], FORM_FIELDS_MAPPING.SOLVA[l])
  );

  const regExp = new RegExp('SOLVA[0-9]{0,}');
  const [getInformationFields] = (displays ?? [])
    .filter((d) => d.displayCd.replace(regExp, 'SOLVA') === 'SOLVA')
    .map((df) => df.fields.split(','));
  const fields = solvaFields.filter((f) =>
    (getInformationFields ?? []).includes(f)
  );

  useEffect(() => {
    setAppOptions({
      isMounted: !isPageLoading,
    });
  }, [isPageLoading]);

  return (
    !isPageLoading && (
      <Section title={t('incomesAndExpenses.title')}>
        <CustomedBubbleInfo
          id="solva-bulles"
          description={t('incomesAndExpenses.bubbleInfo')}
        />
        <IncomeExpensesForm fields={fields} />
      </Section>
    )
  );
};

export default IncomeExpenses;
