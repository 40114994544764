import axios, { AxiosInstance } from 'axios';
import mem from 'memoizee';
import { ERRORDETAILS } from './constants';
import axiosRetry from 'axios-retry';

import { ERRORKEY } from '@vat/configuration';
export interface IAppConfig {
  msBffBaseURL: string;
  timeout: number;
  env: string;
  apiKeyForTokenAPI: string;
}

declare global {
  interface WindowWithEnv extends Window {
    _env_: IAppConfig;
  }
}
const RETRY_INTERVALS_MS = 1000; //  milliseconds

export default function axiosInstance(): AxiosInstance {
  return mem(
    (): AxiosInstance => {
      const axiosBack = axios.create({
        /* eslint no-underscore-dangle: 0 */
        baseURL: (window as unknown as WindowWithEnv)._env_?.msBffBaseURL,
        timeout: 180000,
        timeoutErrorMessage: 'ECONNTIMEOUT',
        withCredentials: true,
      });

      // configuration Retry
      axiosRetry(axiosBack, {
        retries: 2,
        retryCondition: (error) => error?.response?.status === 502,
        retryDelay: () => RETRY_INTERVALS_MS,
      });

      axiosBack.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (error.response === undefined) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject(error);
          }
          const errCode = error.response.status;
          const resp = error.response.data;
          localStorage.setItem(ERRORKEY, error.request.status);
          localStorage.setItem(
            ERRORDETAILS,
            `Page: ${window.location.href} , Service: ${
              resp?.path ?? '-'
            }, Service Error: ${resp?.error ?? '-'}, Service Status Code: ${
              resp?.status ?? '-'
            }, Error Message: ${resp?.message ?? '-'}, $OtherReasons`
          );
          return Promise.reject(errCode);
        }
      );
      return axiosBack;
    },
    {
      primitive: true,
    }
  )();
}
