import { IOption } from '@sweb-front/types';
import { IDepartments } from '@vat/types';
import { LocalStorageService } from '@vat/utils';
import axiosInstance from './httpCommon';

export const executeSearch = async (url: string, value: string) => {
  let computedValue = value;
  if (/\,/.test(value)) {
    [computedValue] = computedValue.split(',');
  }
  computedValue = computedValue.replace(/[0-9\,\-\_]+/gi, '');
  try {
    return (
      await axiosInstance().get(url, {
        params: {
          city: computedValue,
        },
      })
    ).data;
  } catch (err) {
    console.error('autocomplete::executeSearch contains error');
    const message = err.response
      ? err.response.data
      : `Could not get cities. ${err}`;
    console.warn('Service getcities est ko', message);
    return [];
  }
};

export const getCountries = (
  onFailure: (message: string) => void
): Promise<IOption[]> => {
  const STORAGE_KEY = 'countries';
  const data = LocalStorageService.getWithExpiry(STORAGE_KEY);
  if (data) {
    return Promise.resolve(JSON.parse(data));
  }

  return axiosInstance()
    .get('/localities/countries', {})
    .then((response) => {
      const listTransformed = response.data;
      LocalStorageService.setWithExpiry(
        STORAGE_KEY,
        JSON.stringify(listTransformed)
      );

      return Promise.resolve(listTransformed);
    })
    .catch((err) => {
      const message = err.response
        ? err.response.data
        : `Could not get countries. ${err}`;
      onFailure(message);
    });
};

export const getDepartments = (
  onFailure: (message: string) => void
): Promise<IOption[]> => {
  const STORAGE_KEY = 'departments';
  const data = LocalStorageService.getWithExpiry(STORAGE_KEY);
  if (data) {
    return Promise.resolve(JSON.parse(data));
  }

  return axiosInstance()
    .get('/localities/departments', {})
    .then((responseDepartments) => {
      const listTransformed = responseDepartments.data;
      LocalStorageService.setWithExpiry(
        STORAGE_KEY,
        JSON.stringify(listTransformed)
      );

      return Promise.resolve(listTransformed);
    })
    .catch((err) => {
      const message = err.response
        ? err.response.data
        : `Could not get departments. ${err}`;
      onFailure(message);
    });
};

export const getDepartmentsForCity = (
  city: string,
  onFailure: (message: string) => void
): Promise<IDepartments> => {
  return axiosInstance()
    .get(`/localities/cities/${city}/departments`, {})
    .then((departmentsResponse) => {
      return Promise.resolve(departmentsResponse?.data);
    })
    .catch((err) => {
      const message = err.response
        ? err.response.data
        : `Could not get departments for ${city}. ${err}`;
      onFailure(message);
    });
};

export const getCitiesForDepartment = (
  departmentCd: string,
  onFailure: (message: string) => void
): Promise<IOption[]> => {
  if (!departmentCd) {
    onFailure(`getCitiesForDepartment : departmentCd can't be empty`);
  }

  const STORAGE_KEY = `cities_${departmentCd}`;
  const data = LocalStorageService.getWithExpiry(STORAGE_KEY);
  if (data) {
    return Promise.resolve(JSON.parse(data));
  }

  return axiosInstance()
    .get(`/localities/departments/${departmentCd}/cities`, {})
    .then((responseCities) => {
      const listTransformed = responseCities.data;
      LocalStorageService.setWithExpiry(
        STORAGE_KEY,
        JSON.stringify(listTransformed)
      );

      return Promise.resolve(listTransformed);
    })
    .catch((err) => {
      const message = err.response
        ? err.response.data
        : `Could not get cities. ${err}`;
      onFailure(message);
    });
};

export const getCitiesForZipCode = (
  zipCd: string,
  onFailure: (message: string) => void
): Promise<IOption[]> => {
  if (!zipCd) {
    onFailure(`getCitiesForZipCode : zipCd can't be empty`);
  }

  return axiosInstance()
    .get(`/localities/zipcodes/${zipCd}/cities`, {})
    .then((responseCities) => {
      return Promise.resolve(responseCities.data);
    })
    .catch((err) => {
      const message = err.response
        ? err.response.data
        : `Could not get cities. ${err}`;
      onFailure(message);
    });
};
