/**
 * @hook This hook is used to manage in every page if we have to redirect  to the right page
 */

import {
  getActiveStep,
  INavigationPageState,
  IStep,
  selectNavigation,
  selectSteps,
  TPageName,
  updateParcoursNavigation,
  useAppDispatch,
  useAppSelector,
} from '@sweb-front/store';
import { IUrlData } from '@sweb-front/types';
import { isStringEmpty } from '@sweb-front/utils';
import {
  ERRORKEY,
  ERRORPAGE,
  OPPORTUNITYSTATUS,
  REFUSEDREPONSE,
  SUCCESSREPONSE,
  WAITINGRESPONSE,
} from '@vat/configuration';
import { createAndSubmitForm, IForm, PageLoadingContext } from '@vat/utils';

import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const getLastLockedPage = (navStates: INavigationPageState[]) => {
  if (!navStates) {
    return undefined;
  }

  for (let i = navStates.length - 1; i >= 0; i--) {
    if (!!navStates[i]?.locked) {
      return navStates[i];
    }
  }
  return undefined;
};

/**
 * We have pages that are not part of steps like ObConnexionError,
 * In this case, we need the page state that are just before this page that are not part from steps
 * Return the page from steps
 * @param navStates
 * @returns
 */
export const getLastStepIndexOrLockedIndex = (
  navStates: INavigationPageState[],
  steps: IStep[]
) => {
  if (!navStates || navStates.length < 1) {
    return undefined;
  }

  if (!steps) {
    return undefined;
  }

  const lastLockedPage = getLastLockedPage(navStates);

  if (!lastLockedPage) {
    return undefined;
  }

  const stepFoundIndex = steps?.findIndex((st) =>
    st.paths.includes(lastLockedPage.name)
  );

  if (stepFoundIndex > -1) {
    return stepFoundIndex;
  }

  return steps?.findIndex((st) =>
    st.paths.includes(navStates[navStates.indexOf(lastLockedPage) - 1]?.name)
  );
};

/**
 * Get all pages that can still be navigated after locked pages
 * @param navStates
 */
export const getRestAvailablePages = (
  navStates: INavigationPageState[],
  steps: IStep[]
) => {
  const lastPageIndex = getLastStepIndexOrLockedIndex(navStates, steps);

  if (!navStates) {
    return steps;
  }

  if (!steps) {
    return [];
  }

  if (lastPageIndex < 0) {
    return steps;
  }

  return steps.slice(lastPageIndex + 1);
};

const usePageRedirection = (
  page: TPageName,
  params?: Record<string, unknown>
) => {
  const steps = useAppSelector(selectSteps) ?? ([] as IStep[]);
  const navigation = useAppSelector(selectNavigation);
  const dispatch = useAppDispatch();
  const pageStates =
    navigation?.appNavigationState ?? ([] as unknown as INavigationPageState[]);

  const navigate = useNavigate();
  const { steps: navSteps } = navigation;

  const lastLockedPage = getLastLockedPage(pageStates);
  const availablePages = getRestAvailablePages(pageStates, steps);
  const currentPageState = pageStates[pageStates?.length - 1] ?? undefined;
  const pageByName = pageStates.find((p) => p.name === page);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const updatePageIsLoading = useContext(PageLoadingContext);
  const opportunityStatus = navigation?.opportunityStatus;

  // Infos sur la dernière étape non faite
  // Si l'état est disabled, cela veut dire qu'on ne peut pas être sur cette page
  const firstUnfinishedPage = pageStates?.find(
    (p) => !p.actionPageDone && !p.disabled
  );

  const errorCode = localStorage.getItem(ERRORKEY);
  const firstUnfinishedPageIndex = pageStates?.indexOf(firstUnfinishedPage);
  const pageByNameIndex = pageStates?.indexOf(pageByName);

  useEffect(() => {
    setIsPageLoading(true);
    let isDone = false;
    let url = '';

    if (opportunityStatus === OPPORTUNITYSTATUS.ANUL) {
      isDone = true;
      url = ERRORPAGE;
    } else if (opportunityStatus === OPPORTUNITYSTATUS.APPR) {
      isDone = true;
      url = SUCCESSREPONSE;
    } else if (
      navigation?.endParcours &&
      opportunityStatus === OPPORTUNITYSTATUS.REFU
    ) {
      isDone = true;
      url = REFUSEDREPONSE;
    } else if (
      navigation?.endParcours &&
      opportunityStatus === OPPORTUNITYSTATUS.PAPP
    ) {
      isDone = true;
      url = WAITINGRESPONSE;
    }

    if (isDone) {
      navigate(url, {
        replace: true,
      });
      updatePageIsLoading(false);
      return setIsPageLoading(false);
    }

    // Si nous sommes dans un débranchement externe et qu'on ne peut plus revenir sur le parcours
    // Pour le cas monext et open-banking, on peut encore revenir sur le parcours
    if (
      !!getActiveStep(navSteps) &&
      !['openBanking', 'monext'].includes(
        getActiveStep(navSteps).externalAppName
      )
    ) {
      return;
    }

    // Nous sortons du traitement si on a des erreurs au cours du parcours
    // ou lors des débranchements
    if (
      currentPageState?.params?.hasError ||
      (getActiveStep(navSteps)?.isErrorHappened && !isStringEmpty(errorCode)) ||
      (!isStringEmpty(errorCode) && errorCode === '401')
    ) {
      updatePageIsLoading(false);
      setIsPageLoading(false);
      return;
    }

    // Si nous sommes sur une page qui doit être bloquée et en cours, nous devons rester sur cette page
    else if (
      lastLockedPage?.locked &&
      !lastLockedPage?.actionPageDone &&
      availablePages.some((st) => st.paths.includes(lastLockedPage.name))
    ) {
      navigate(lastLockedPage.name, {
        replace: true,
      });
    }

    // Sinon il peut encore parcourir dans les autres pages qui peuvent être naviguées.
    // Mais il faut s'assurer que cela ne dépasse pas la page qui est cours,
    // Ce qui veut dire qu'il doit d'abord valider la page courante pour pouvoir passer dans la prochaine étape
    else if (
      pageByName &&
      availablePages.some((pg) => pg.paths.includes(pageByName.name)) &&
      pageByNameIndex >= 0 &&
      firstUnfinishedPageIndex > pageByNameIndex
    ) {
      navigate(pageByName.name, {
        replace: true,
      });
    }

    // Sinon on lui envoie vers la première tâche qui n'est pas terminée
    else if (firstUnfinishedPage) {
      if (!isStringEmpty(firstUnfinishedPage.params?.url as string)) {
        if (firstUnfinishedPage.params?.method === 'POST') {
          const requestParams = firstUnfinishedPage.params
            .dataRetrievedToExternalUrl as Record<string, unknown>;

          return createAndSubmitForm(
            (firstUnfinishedPage.params?.url as string) ?? '',
            'POST',
            requestParams.formDetails as IForm,
            (requestParams?.urlData as IUrlData[]) ?? ([] as IUrlData[])
          );
        }
        // si method GET (par défaut)
        return window.location.replace(
          firstUnfinishedPage.params?.url as string
        );
      }
      navigate(firstUnfinishedPage.name, {
        replace: true,
      });
    } else if (page !== ERRORPAGE) {
      // On initie dans chaque page l'étape
      dispatch(
        updateParcoursNavigation({
          name: page,
          loaded: true,
          ...params,
        })
      );
    }
    updatePageIsLoading(false);
    setIsPageLoading(false);
  }, []);

  return { isPageLoading };
};

export default usePageRedirection;
